// const colorArrL = [
//   "dclbeige-100",
//   "dclbeige-200",
//   "dclbeige-300",
//   "dclpal1-200",
//   "dclpal1-300",
//   "dclpal1-400",
//   "white",
// ];
const colorArrL = [
  "white",
  "white",
  "white",
  "white",
  "white",
  "white",
  "white",
];
// const colorArrD = [
//   "dclevergreen-100",
//   "dclevergreen-200",
//   "dclnavy-100",
//   "dclnavy-200",
//   "dclnavy-300",
//   "dclpal1-100",
//   "dclpal1-100",
// ];
const colorArrD = [
  "dclpal1-100",
  "dclpal1-100",
  "dclpal1-100",
  "dclpal1-100",
  "dclpal1-100",
  "dclpal1-100",
  "dclpal1-100",
];

const colorsObj = {
  colorArrL,
  colorArrD,
};

export default colorsObj;
